body {
	margin: 0;
	background-color: $secondary;
	background-image: url("../images/worldservice-tile-deepblue.jpg");
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
	font-family: "Rubik", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

.serif-font {
	font-family: "Open Sans", "Times New Roman", serif;
}

a {
	color: #9c27b0;
}
a:hover,
a:focus {
	color: #89229b;
	text-decoration: none;
}
a.text-info:hover,
a.text-info:focus {
	color: #00a5bb;
}
a .material-icons {
	vertical-align: middle;
}

legend {
	border-bottom: 0;
}

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}
*:focus {
	outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	outline: 0 !important;
}

.image-container {
	min-height: 100vh;
	background-position: center center;
	background-size: cover;
	position: relative;
}
.image-container:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.3;
}

.made-with-mk {
	width: 50px;
	height: 50px;
	display: block;
	position: fixed;
	z-index: 555;
	bottom: 40px;
	right: 40px;
	border-radius: 30px;
	background-color: rgba(16, 16, 16, 0.35);
	border: 1px solid rgba(255, 255, 255, 0.15);
	color: #ffffff;
	cursor: pointer;
	padding: 10px 12px;
	white-space: nowrap;
	overflow: hidden;
	-webkit-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	-moz-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	-o-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
}
.made-with-mk:hover,
.made-with-mk:active,
.made-with-mk:focus {
	width: 218px;
	color: #ffffff;
	transition-duration: 0.55s;
	padding: 10px 19px;
}
.made-with-mk:hover .made-with,
.made-with-mk:active .made-with,
.made-with-mk:focus .made-with {
	opacity: 1;
}
.made-with-mk:hover .brand,
.made-with-mk:active .brand,
.made-with-mk:focus .brand {
	left: 0;
}
.made-with-mk .brand,
.made-with-mk .made-with {
	float: left;
}
.made-with-mk .brand {
	position: relative;
	top: 4px;
	left: -1px;
	letter-spacing: 1px;
	vertical-align: middle;
	font-size: 16px;
	font-weight: 600;
}
.made-with-mk .made-with {
	color: rgba(255, 255, 255, 0.6);
	position: absolute;
	left: 58px;
	top: 14px;
	opacity: 0;
	margin: 0;
	-webkit-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	-moz-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	-o-transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
	transition: 0.55s cubic-bezier(0.6, 0, 0.4, 1);
}
.made-with-mk .made-with strong {
	font-weight: 400;
	color: rgba(255, 255, 255, 0.9);
}

.wizard-container {
	padding-top: 20px;
	margin-bottom: 30px;
	z-index: 3;
}
.wizard-container .wizard-navigation {
	position: relative;
}

h1,
.h1 {
	font-size: 3.8em;
	line-height: 1.15em;
}

h2,
.h2 {
	font-size: 2.6em;
}

h3,
.h3 {
	font-size: 1.825em;
	line-height: 1.4em;
	margin: 20px 0 10px;
}

h4,
.h4 {
	font-size: 1.3em;
	line-height: 1.4em;
}

h5,
.h5 {
	font-size: 1.25em;
	line-height: 1.4em;
	margin-bottom: 15px;
}

h6,
.h6 {
	font-size: 0.9em;
	text-transform: uppercase;
}

.title,
.card-title,
.wizard-title {
	font-weight: 700;
	margin-bottom: 30px;
}
.title,
.title a,
.card-title,
.card-title a,
.wizard-title,
.wizard-title a {
	color: #3c4858;
	text-decoration: none;
}

h2.title {
	margin-bottom: 30px;
}

.card-header,
.card-footer {
	background-color: transparent !important;
}

.description,
.card-description,
.footer-big p {
	color: #999999;
}

.text-warning {
	color: #ff9800;
}

.text-primary {
	color: #9c27b0;
}

.text-danger {
	color: #f44336;
}

.text-success {
	color: #4caf50;
}

.text-info {
	color: #00bcd4;
}

.card {
	background-color: #ffffff;
	padding: 10px 0;
	width: 100%;
	border-radius: 6px;
	color: rgba(0, 0, 0, 0.87);
	background: #fff;
}

.card-wizard {
	min-height: 410px;
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card-wizard .picture-container {
	position: relative;
	cursor: pointer;
	text-align: center;
}
.card-wizard .picture {
	width: 106px;
	height: 106px;
	background-color: #999999;
	border: 4px solid #cccccc;
	color: #ffffff;
	border-radius: 50%;
	margin: 5px auto;
	overflow: hidden;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
}
.card-wizard .picture:hover {
	border-color: #2ca8ff;
}
.card-wizard[data-color="primary"] .moving-tab {
	position: absolute;
	text-align: center;
	padding: 12px;
	font-size: 12px;
	text-transform: uppercase;
	-webkit-font-smoothing: subpixel-antialiased;
	background-color: $primary;
	top: -4px;
	left: 0px;
	border-radius: 4px;
	color: #ffffff;
	cursor: pointer;
	font-weight: 700;
	box-shadow: 0 16px 26px -10px rgba(0, 188, 212, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 188, 212, 0.2);

	.mdi {
		vertical-align: middle;

		&::before {
			font-size: 16px;
			margin-right: 10px;
		}
	}
}
.card-wizard .picture input[type="file"] {
	cursor: pointer;
	display: block;
	height: 100%;
	left: 0;
	opacity: 0 !important;
	position: absolute;
	top: 0;
	width: 100%;
}
.card-wizard .picture-src {
	width: 100%;
}
.card-wizard .tab-content {
	min-height: 300px;
	padding: 0 15px;
}
.card-wizard .wizard-footer {
	padding: 0 15px;
}
.card-wizard .wizard-footer .checkbox {
	margin-top: 16px;
}
.card-wizard .disabled:not(.btn) {
	display: none;
}
.card-wizard .wizard-header {
	text-align: center;
	padding: 25px 0 35px;
}
.card-wizard .wizard-header h5 {
	margin: 5px 0 0;
}
.card-wizard .nav-pills > li {
	text-align: center;
}
.card-wizard .btn {
	text-transform: uppercase;
}
.card-wizard .info-text {
	text-align: center;
	font-weight: 300;
	margin: 10px 0 30px;
}
.card-wizard .choice {
	text-align: center;
	cursor: pointer;
	margin-top: 20px;
}
.card-wizard .choice .icon {
	text-align: center;
	vertical-align: middle;
	height: 116px;
	width: 116px;
	border-radius: 50%;
	color: #999999;
	margin: 0 auto 20px;
	border: 4px solid #cccccc;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
}
.card-wizard .choice i {
	font-size: 40px;
	line-height: 111px;
}
.card-wizard .choice:hover .icon,
.card-wizard .choice.active .icon {
	border-color: #2ca8ff;
}
.card-wizard .choice input[type="radio"],
.card-wizard .choice input[type="checkbox"] {
	position: absolute;
	left: -10000px;
	z-index: -1;
}
.card-wizard .btn-finish {
	display: none;
}
.card-wizard .description {
	color: #999999;
	font-size: 14px;
}
.card-wizard .wizard-title {
	margin: 0;
}

legend {
	margin-bottom: 20px;
	font-size: 21px;
}

output {
	padding-top: 8px;
	font-size: 14px;
	line-height: 1.42857;
}

.btn {
	border: none;
	border-radius: 3px;
	position: relative;
	padding: 12px 30px;
	margin: 10px 1px;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0;
	will-change: box-shadow, transform;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn::-moz-focus-inner {
	border: 0;
}
.btn,
.btn.btn-default {
	box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2),
		0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn,
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn:active:hover,
.btn.active:focus,
.btn.active:hover,
.open > .btn.dropdown-toggle,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle:hover,
.btn:focus,
.btn:active,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:hover {
	box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
.btn.btn-primary {
	box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.open > .btn.btn-primary.dropdown-toggle,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover {
	background-color: $primary;
	color: #ffffff;
}
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover {
	box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover,
.btn.btn-primary.disabled:focus,
.btn.btn-primary.disabled.focus,
.btn.btn-primary.disabled:active,
.btn.btn-primary.disabled.active,
.btn.btn-primary:disabled,
.btn.btn-primary:disabled:hover,
.btn.btn-primary:disabled:focus,
.btn.btn-primary:disabled.focus,
.btn.btn-primary:disabled:active,
.btn.btn-primary:disabled.active,
.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover,
.btn.btn-primary[disabled]:focus,
.btn.btn-primary[disabled].focus,
.btn.btn-primary[disabled]:active,
.btn.btn-primary[disabled].active,
fieldset[disabled] .btn.btn-primary,
fieldset[disabled] .btn.btn-primary:hover,
fieldset[disabled] .btn.btn-primary:focus,
fieldset[disabled] .btn.btn-primary.focus,
fieldset[disabled] .btn.btn-primary:active,
fieldset[disabled] .btn.btn-primary.active {
	box-shadow: none;
}
.btn.btn-success {
	box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2),
		0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success,
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.open > .btn.btn-success.dropdown-toggle,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle:hover {
	background-color: #4caf50;
	color: #ffffff;
}
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover {
	box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.disabled,
.btn.btn-success.disabled:hover,
.btn.btn-success.disabled:focus,
.btn.btn-success.disabled.focus,
.btn.btn-success.disabled:active,
.btn.btn-success.disabled.active,
.btn.btn-success:disabled,
.btn.btn-success:disabled:hover,
.btn.btn-success:disabled:focus,
.btn.btn-success:disabled.focus,
.btn.btn-success:disabled:active,
.btn.btn-success:disabled.active,
.btn.btn-success[disabled],
.btn.btn-success[disabled]:hover,
.btn.btn-success[disabled]:focus,
.btn.btn-success[disabled].focus,
.btn.btn-success[disabled]:active,
.btn.btn-success[disabled].active,
fieldset[disabled] .btn.btn-success,
fieldset[disabled] .btn.btn-success:hover,
fieldset[disabled] .btn.btn-success:focus,
fieldset[disabled] .btn.btn-success.focus,
fieldset[disabled] .btn.btn-success:active,
fieldset[disabled] .btn.btn-success.active {
	box-shadow: none;
}
.btn.btn-success.btn-simple {
	background-color: transparent;
	color: #4caf50;
	box-shadow: none;
}
.btn.btn-success.btn-simple:hover,
.btn.btn-success.btn-simple:focus,
.btn.btn-success.btn-simple:active {
	background-color: transparent;
	color: #4caf50;
}
.btn:focus,
.btn:active,
.btn:active:focus {
	outline: 0;
}
.btn-secondary {
	background-color: #3c4858;
}
/*            Navigation menu                */
.nav-pills {
	background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills {
	.mdi {
		display: inline;
		vertical-align: bottom;

		&::before {
			font-size: 16px;
			margin-right: 10px;
		}
	}
}
.nav-pills > li + li {
	margin-left: 0;
}
.nav-pills > li > a {
	border: 0 !important;
	border-radius: 0;
	line-height: 18px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	min-width: 100px;
	text-align: center;
	color: #555555 !important;

	&.active {
		background-color: transparent !important;
	}
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
	background-color: inherit;
}
.nav-pills > li i {
	display: block;
	font-size: 30px;
	padding: 15px 0;
}

// Custom radio
.lds-ellipsis {
	display: flex;
	position: relative;
	width: 80px;
	height: 80px;

	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: $primary;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}

		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.custom-radio {
	background-color: $primary;
	display: block;
	margin: 10px 0;
	position: relative;
	border-radius: 4px;

	label {
		padding: 12px 30px;
		width: 100%;
		display: block;
		text-align: left;
		color: #fff;
		cursor: pointer;
		position: relative;
		z-index: 2;
		transition: color 200ms ease-in;
		overflow: hidden;

		&:before {
			width: 100%;
			height: 100%;
			content: "";
			background-color: #3c4858;
			position: absolute;
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			z-index: -1;
		}

		&:after {
			width: 32px;
			height: 32px;
			content: "";
			border: 2px solid #d1d7dc;
			background-color: #fff;
			background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			background-position: 2px 3px;
			border-radius: 4px;
			z-index: 2;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			transition: all 200ms ease-in;
		}
	}

	input:checked ~ label {
		color: #fff;
		border-radius: 4px;

		&:before {
			transform: translate(-50%, -50%) scale3d(56, 56, 1);
			opacity: 1;
		}

		&:after {
			background-color: #54e0c7;
			border-color: #54e0c7;
		}
	}

	input {
		width: 32px;
		height: 32px;
		order: 1;
		z-index: 2;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		visibility: hidden;
	}
}

.form-group {
	label {
		font-weight: 600;
	}

	.invalid-feedback {
		display: block;
		font-size: .8rem;
	}

	input[type="file"] {
		display: none;
	}

	.inputfile + label {
			max-width: 80%;
			font-weight: 700;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			display: inline-block;
			overflow: hidden;
			padding: 0.625rem 1.25rem;
	}

	.inputfile:focus + label,
	.inputfile.has-focus + label {
			outline: 1px dotted #000;
			outline: -webkit-focus-ring-color auto 5px;
	}

	.inputfile + label svg {
			width: 1em;
			height: 1em;
			vertical-align: middle;
			fill: currentColor;
			margin-top: -0.25em;
			margin-right: 0.25em;
	}
	.inputfile-custom + label {
			color: $primary;
	}

	.inputfile-custom + label {
			border: 1px solid $primary;
			background-color: #DCEDFC;
			padding: 0;
			width: 100%;
	}

	.inputfile-custom:focus + label,
	.inputfile-custom.has-focus + label,
	.inputfile-custom + label:hover {
			border-color: #16A6CD;
	}

	.inputfile-custom + label span,
	.inputfile-custom + label strong {
			padding: 0.625rem 1.25rem;
	}

	.inputfile-custom + label span {
			min-height: 2em;
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			vertical-align: top;
			max-width: 55%;
	}

	.inputfile-custom + label strong {
			height: 100%;
			color: #FFFFFF;
			background-color: $primary;
			float: right;
	}

	.inputfile-custom:focus + label strong,
	.inputfile-custom.has-focus + label strong,
	.inputfile-custom + label:hover strong {
			background-color: #16A6CD;
	}

	@media screen and (max-width: 50em) {
		.inputfile-custom + label strong {
			display: block;
		}
	}
}

.flatpickr-input {
	display: none!important;
}

.hour-selection {
	max-height: 320px;
	overflow: hidden;
	overflow-y: auto;
}

.breadcrumb {
	font-size: 14px;

	i {
		margin-right: 10px;
	}
}

.branch-qty {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFF;
	background-color: #3c4858;
	margin: 0 10px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

@media (max-width: 480px) {
    .px-xs-0 {
       padding-left: 0;
			 padding-right: 0;
    }
}

// de aca abajo es lo que se agrega para bsc
// color rgb(17, 109, 217);
.out {
	outline: 1px solid red;
}

@mixin button-reset {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer;
}

.lounge-button {
	@include button-reset;
	text-align: start;
}

.card-button {
	@include button-reset;
	border-radius: 0.25rem;
	box-shadow: 0px 3px 6px #00000044;
	padding: 1rem;
	width: 12rem;
	min-height: 7rem;
	font-size: 1.2rem;
	font-weight: 800;
	
}

.card-service {
	transition: transform 300ms;
	display: flex;
    flex-direction: column;
}

.card-selected,
.card-selected:focus {
	outline: 3px solid $primary;
}

.card-primary {
	background-color: $primary;
	color: white;
	//color: $primary;
}

.card-service:hover {
	transform: scale(1.05);
}
.card-service >div:first-child {
	text-align: left;
	padding-bottom: 1rem;
	font-size: 1.5rem;
	text-decoration: underline;
	text-decoration-thickness: 4px;
	color: $primary;
}


.card-service:hover >div:last-child {
	text-decoration: underline;
}

.card-service >div {
	text-align: left;

}